export const DENVER_ADDRESS = '3200 Cherry Creek S Dr, Denver, CO 80209'
export const DENVER_STREET_ADDRESS = '3200 Cherry Creek S Dr #640'
export const DENVER_CITY_STATE_ZIP = 'Denver, CO 80209'
export const HOMEPAGE_URL = '/'
export const HOW_IT_WORKS_URL = '/how-it-works'
export const DST_URL = '/1031'
export const ABOUT_US_URL = '/about-us'
export const FAQ_URL = '/faq'
export const PRIVACY_POLICY_URL = '/privacy'
export const ONBOARDING_URL = '/onboarding'
export const CAREERS_URL = '/careers'
export const PORTFOLIO_MAILER_URL = '/portfolio-owner'
export const PORTFOLIO_URL = '/portfolio'
export const DST_PORTFOLIO_URL = '/1031/portfolio'
export const AGENTS_URL = '/agents'
export const AGENTS_LEAD_SUCCESS_URL = '/agents/address-success'
export const AGENTS_EXAMPLE_OFFER_URL = '/agents/example-offer-success'
export const AGENTS_SCHEDULE_SUCCESS = '/agents/schedule-call-success'
export const DST_LANDING_PAGE_SOURCE = 'dst_landing_page'
export const FLOCK_EMAIL = 'info@flockhomes.com'
export const BLOG_URL = '/blog'
export const INVESTOR_URL = process.env.GATSBY_FLOCK_INVESTOR_URL || '/'
export const PARTNER_URL = process.env.GATSBY_FLOCK_PARTNER_URL
export const GOOGLE_MAPS_API_KEY = process.env.GATSBY_GOOGLE_MAPS_API_KEY
export const CALENDLY_SCHEDULE_URL =
  'https://calendly.com/d/cks5-mpk-d8h/flock-homes-information-session'
export const DEFAULT_SALES_OPERATOR = 'Raymond Hu'
export const PRELIM_VALUATION_TYPE = 'VALUATION_TYPE_PRELIM'
export const DEFAULT_SALES_SLACK_ID = 'U06NRFYC8HJ'
export const DEFAULT_SALES_EMAIL = 'raymond@flockhomes.com'
export const DEFAULT_SALES_PHONE_NUMBER = '(720) 703-9992'
// 30 minute intro call that is with CGAs
export const DEFAULT_SALES_CALENDLY =
  'https://calendly.com/d/cks5-mpk-d8h/flock-homes-information-session'
export const AGENT_SALES_SLACK_ID = 'U03TSL4TM0W'
export const CALENDLY_LANDING_URL =
  'https://calendly.com/d/cks5-mpk-d8h/flock-homes-information-session'
export const CALENDLY_AGENTS_URL =
  'https://calendly.com/d/cks5-mpk-d8h/flock-homes-information-session'
export const AGENT_SCHEDULE_URL =
  'https://calendly.com/d/cks5-mpk-d8h/flock-homes-information-session'
export const AGENTS_SALES_OPERATOR = 'Raymond Hu'
export const MAPBOX_TOKEN = process.env.GATSBY_MAPBOX_TOKEN
export const SAM_OPERATOR_UUID = '778bb9f9-464b-4766-9d87-9da403ae05ac'
export const JACOB_OPERATOR_UUID = 'f9c0e463-48a3-4b89-a7ce-437cd8cb8ad2'
export const ARI_OPERATOR_UUID = 'a7014674-a842-437e-95da-442c430697e6'
export const COLIN_OPERATOR_UUID = '55a91781-9619-4f4e-8804-05aa76c3d71c'
export const JEREMY_OPERATOR_UUID = '7b882957-7563-48d1-99bd-b0e30f684a55'
export const JACK_OPERATOR_UUID = '2214ea6a-b61a-4cd7-ba22-c9caab2c9849'
export const CHAD_OPERATOR_UUID = 'd0589bf7-246b-4440-945c-52c31787beb1'
export const ELLIOT_OPERATOR_UUID = 'c28f628a-966d-48e4-9ef1-ddfc799a578d'
export const KYLE_OPERATOR_UUID = 'f90983d9-a363-4348-953e-df773668bf57'
export const JENNETTE_OPERATOR_UUID = '2e374430-a261-41c0-a663-98cf9b5efbe3'
export const BOOK_A_DEMO_CALENDLY =
  'https://calendly.com/d/3bf-9cx-8wv/flock-homes-demo'

export const { GATSBY_ENV } = process.env

export const LPA_URL =
  'https://flock-static-documents.s3.us-east-2.amazonaws.com/production/[DRAFT]%20Flock%20Homes%20-%20Amended%20and%20Restated%20Agreement%20of%20Limited%20Partnership%20(2024)'
export const PPM_URL =
  'https://flock-public-docs.s3.us-east-2.amazonaws.com/Flock+Private+Placement+Memorandum.pdf'
export const PROPERTY_STANDARDS_URL =
  'https://flock-static-documents.s3.us-east-2.amazonaws.com/production/Flock%20Standards%20-%20Inspection%20Adjustments'
export const OVERVIEW_URL =
  'https://flock-public-docs.s3.us-east-2.amazonaws.com/Flock+Two+Pager.pdf'
export const DST_OVERVIEW_URL =
  'https://flock-public-docs.s3.us-east-2.amazonaws.com/Flock+Homes+Overview+(1031+DST).pdf'
export const DENVER_POST_ARTICLE_URL =
  'https://www.denverpost.com/2021/04/10/denver-flock-homes-startup-real-estate/'
export const BUSINESS_INSIDER_URL =
  'https://www.businessinsider.com/this-startup-retirement-solution-single-family-rental-landlords-2022-3'
export const RECENT_FUND_PERFORMANCE_URL =
  'https://flock-public-docs.s3.us-east-2.amazonaws.com/Flock+Quarterly+Fact+Sheet.pdf'
export const ACQUISITION_REPORT_URL =
  'https://flock-public-docs.s3.us-east-2.amazonaws.com/Flock+Acquisition+Report.pdf'
export const TECH_CRUNCH_ARTICLE =
  'https://techcrunch.com/2022/03/24/flock-homes-closes-on-an-a16z-led-26m-series-a/'
export const ANDREESSEN_ARTICLE =
  'https://a16z.com/2022/03/24/investing-in-flock/'
export const KPMG_AUDIT_URL =
  'https://flock-static-documents.s3.us-east-2.amazonaws.com/production/Audited+2022+Flock+Homes+OP+LP+Financials.pdf'
export const RECENT_FUND_FINANCIALS_URL =
  'https://flock-static-documents.s3.us-east-2.amazonaws.com/production/Q2+2024+Flock+Homes+OP+LP+Financials.pdf'
export const FLOCK_WEBINAR_231102 =
  'https://flockhomes.docsend.com/view/hynrwwfrt4jpti6m'

export const GENERIC_OFFER_PAGE_DOCUMENTS = [
  {
    contentUrl:
      'https://flock-static-documents.s3.us-east-2.amazonaws.com/production/Trustpilot+Reviews+Image',
    documentType: 'educational',
    name: 'Trustpilot Reviews',
    uuid: '',
  },
  {
    contentUrl: RECENT_FUND_PERFORMANCE_URL,
    documentType: 'performance',
    name: 'Fact Sheet',
    uuid: '',
  },
  {
    contentUrl: RECENT_FUND_FINANCIALS_URL,
    documentType: 'performance',
    name: 'Fund Financials',
    uuid: '',
  },
  {
    contentUrl: KPMG_AUDIT_URL,
    documentType: 'performance',
    name: 'KPMG Audit',
    uuid: '',
  },
]
